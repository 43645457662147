.react-carousel {
    flex: 1 1 auto;
  }
  
  .react-carousel-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0 0;
  }

.carousel {
    height: 100%;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none;
    background: #6b63ff;
    width: 10px;
    height: 10px;
  }
  
  .carousel .slide img {
    width: auto;

  }

  .control-arrow{
    border-color: red;
  }
  
  .image-wrapper {
    width: 160px;
    height: 160px;
    margin-bottom: 10px;
  }